@import url('~assets/appui/css/bootstrap.min.css');
@import url('~assets/appui/css/plugins.css');
@import url('~assets/appui/css/main.css');
@import url('~assets/appui/css/themes/peces.css');
@import url('~assets/appui/css/themes.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('~helpers.scss');
:root {
    --font-primary: 'Roboto';
    --font-secondary: 'Roboto';
    --font-size: 14px;
    --color-primary: #00b8a8;
    --color-primary-hover: #07a799;
    --color-secondary: #011d36;
    --color-secondary-hover: #02294b;
    --color-form-control:  #02294b;
    --header-glass: #092c3b;
    --btn-default: #F0F0F0;
    --btn-default-hover: #DDD;
    --btn-primary: #04C8B8;
    --btn-primary-hover: #07a799;
    --btn-secondary: #FFF;
    --btn-secondary-hover: #1f1d1d;
    --btn-login: #011d36;
    --btn-login-hover: #02294b;
    --logo-height: 160px;
    --logo-height-movil: 150px;
    --logo-menu-width: 60%;
    --bg-body: #ebeff2;
    --bg-01: #F2BB2F;
    --bg-02: #003A70;
    --bg-03: #5AB0E3;
    --bg-04: #E73351;
    --bg-05: #F2BB2F;
    --display-triangulo-left-header: block;
    --display-triangulo-right-header: block;
    --cd-color-event-1: #02294b;
}

body {
    background-color: var(--bg-body);
    font-family: var(--font-primary);
    font-size: var(--font-size);
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
}

#page-content {
    background-color: #f6f7f9;
}

.font-primary {font-family: var(--font-primary) !important;}
.font-secondary {font-family: var(--font-secondary) !important;}

.text-primary {color: var(--color-primary)}

.navbar.navbar-inverse.navbar-glass {
    background: var(--header-glass) ;
}

.btn-default {
    background-color: var(--btn-default);
    border-color: var(--btn-default);
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.open .btn-default.dropdown-toggle,
.open .btn-default.dropdown-toggle:hover,
.open .btn-default.dropdown-toggle:focus,
.open .btn-default.dropdown-toggle.focus {
    background-color: var(--btn-default-hover);
    border-color: var(--btn-default-hover);
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
.btn-default[disabled]:active:focus,
.btn-default[disabled].active:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active:focus,
fieldset[disabled] .btn-default.active:focus {
    background-color: var(--btn-default-hover);
    border-color: var(--btn-default-hover);
}

.btn-primary,
.fc-state-default {
    background-color: var(--btn-primary);
    border-color: var(--btn-primary);
}
.btn-default {
    background-color: var(--btn-default);
    border-color: var(--btn-default);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.open .btn-primary.dropdown-toggle,
.open .btn-primary.dropdown-toggle:hover,
.open .btn-primary.dropdown-toggle:focus,
.open .btn-primary.dropdown-toggle.focus {
    background-color: var(--btn-primary-hover);
    border-color: var(--btn-primary-hover);
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
.btn-primary[disabled]:active:focus,
.btn-primary[disabled].active:focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active:focus,
fieldset[disabled] .btn-primary.active:focus {
    background-color: var(--btn-primary-hover);
    border-color: var(--btn-primary-hover);
}

.btn-secondary {
    background-color: var(--btn-secondary);
    border-color: var(--btn-secondary);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary.active,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.open .btn-secondary.dropdown-toggle,
.open .btn-secondary.dropdown-toggle:hover,
.open .btn-secondary.dropdown-toggle:focus,
.open .btn-secondary.dropdown-toggle.focus {
    background-color: var(--btn-secondary-hover);
    border-color: var(--btn-secondary-hover);
}

.btn-secondary.disabled,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled]:hover,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled].focus,
.btn-secondary[disabled]:active,
.btn-secondary[disabled].active,
.btn-secondary[disabled]:active:focus,
.btn-secondary[disabled].active:focus,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:active,
fieldset[disabled] .btn-secondary.active,
fieldset[disabled] .btn-secondary:active:focus,
fieldset[disabled] .btn-secondary.active:focus {
    background-color: var(--btn-secondary-hover);
    border-color: var(--btn-secondary-hover);
}

.btn-danger {
    background-color: #E40000;
    border-color: #E40000;
}

#login-container .logo {
    display: inline-block;
    height: 50px;
    margin-right: 10px;
    vertical-align: middle;
    filter: drop-shadow(0 0 5px rgba(0,0,0,.5));
}
@media screen and (min-width: 768px) {
    #login-container {
        width: 385px;
        top: 120px;
        margin-left: -192px;
    }
}
.sidebar-title img {
    height: 30px;
}

.table thead > tr > th,
.table tbody > tr > td {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.content-header h1 {
    display: inline-block;
}
.breadcrumb-top.text-left {
    text-align: left !important;
    padding-left: 0;
}
.my-auto {
    display: flex;
    align-items: center;
    min-height: 100px;
    flex-wrap: wrap;
}
.my-auto .header-section {
    flex-basis: 100%;
}

.table-grid.block {
    padding: 0;
}
@media (max-width: 768px) {
    .table-grid.break-padding {
        margin-left: inherit;
        margin-right: inherit;
    }
    .table-grid.block {
        border: none;
        background: transparent;
        box-shadow: none;
    }
    .table-grid thead {
        display: none;
    }
    .table-grid tbody {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
    }
    .table-grid tbody tr {
        padding: 10px 15px;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-wrap: wrap;
    }
    .table-grid tbody tr td {
        border: none;
    }
    .table-grid tbody tr td.breaktd {
        flex-basis: 100%;
    }
    .table-grid tbody tr td.btntd {
        margin-top: 10px;
        margin-left: auto;
        font-size: 18px;
    }
    .table-grid tbody tr td.btntd ~ td.btntd {
        margin-top: 10px;
        margin-left: 0;
    }
    .table-grid tbody tr td.btntd .btn {
        padding: 0.3rem 0.7rem;
        font-size: 18px;
        line-height: 1.5;
    }
}

.input-group-append {
    display: table-cell;
    padding-top: 0;
    vertical-align: top;
}
.input-group-addon {
    font-size: 19px;
    width: auto;
}
.input-group-addon:first-child {
    border-right: 1px solid #dae0e8;
}

.break-padding {
    margin-left: -20px;
    margin-right: -20px;
}
@media (max-width: 768px) {
    .break-padding {
        margin-left: unset;
        margin-right: unset;
    }
}

.header-section {
    padding-bottom: 0;
}

.img-preview-container {
    display: block;
    border: 1px solid #cbcbcb;
    min-height: 130px;
    margin: 10px;
    text-align: center;
}
.img-preview-container img {
    max-width: 100%;
    max-height: 200px;
}
.row-equal-cols {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
}
.row-equal-cols > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
.col-botones {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row !important;
    padding-bottom: 10px;
}
@media (max-width: 768px) {
    .col-botones {
        margin-top: 20px;
        justify-content: center;
    }
}

.img-circle {
    border-radius: 50%;
}
.img-circle.square {
    width: 40px;
    height: 40px;
}
.img-circle.menu {
    position: relative;
    width: 70%;
    height: auto;
    padding-top: 70%;
}
.img-usuario {
    object-fit: contain;
}
.img-listado {
    width: 36px;
    height: 36px;
    object-fit: cover;
}

.tox-notifications-container {
    display: none !important;
}

.chosen-iconos .chosen-single,
.chosen-iconos .chosen-drop {
    font-size: 16px;
}
.chosen-iconos .chosen-single span,
.chosen-iconos .chosen-drop span {
    height: 40px;
}
.chosen-iconos .chosen-single i,
.chosen-iconos .chosen-drop i {
    opacity: .6;
    font-size: 30px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.chosen-iconos .chosen-container-single .chosen-single div {
    position: static;
    display: inline-block;
}

.calendario-open {
    padding: 10px;
    border: 1px solid #E4E4E4;
    width: 47%;
    display: inline-block;
}
.calendario-open th,
.calendario-open td {
    font-size: 14px;
    padding: 10px !important;
}
.calendario-open .datepicker-inline {
    width: unset !important;
}
.datepicker table tr td.disabled {
    background: #EEE;
    color: #454545;
}
.datepicker table tr td.bloqueada,
.legenda span.bloqueada {
    background: #000;
    color: #FFF;
}
.datepicker table tr td.bloqueada.reservada,
.legenda span.bloqueada.reservada {
    background: #D77248;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.booking ,
.legenda span.bloqueada.booking {
    background: #003990;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.airbnb,
.legenda span.bloqueada.airbnb {
    background: #F73659;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.booking-airbnb {
    position: relative;
    background: transparent;
    color: #FFF;
    pointer-events: none;
    z-index: 1;
}
.datepicker table tr td.bloqueada.booking-airbnb::before {
    content: '';
    display: block;
    background: #003990;
    position: absolute;
    top: 0; bottom: 0; left: 0; width: 50%;
    z-index: -1;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.datepicker table tr td.bloqueada.booking-airbnb::after {
    content: '';
    display: block;
    background: #F73659;
    position: absolute;
    top: 0; bottom: 0; right: 0; width: 50%;
    z-index: -1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.datepicker table tr td:hover {
    background: #000 !important;
    color: #FFF !important;
}
.legenda div {
    padding: 5px 10px;
    font-size: 12px;
    width: fit-content;
    float: left;
}
.legenda span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    vertical-align: middle;
    border-radius: 4px;
}

.resumen-total {
    font-size: 18px;
    padding: 5px 15px;
}
.resumen-total p {
    margin: 0;
}
.resumen-total span {
    font-weight: bold;
    color: var(--color-primary);
}
.resumen-total .total {
    font-weight: bold;
    font-size: 30px;
}
.daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #D77248 !important;
}
table div[contenteditable] {
    background-color: #ececec;
    border-radius: 5px;
    padding: 5px 10px;
}

.title-border-bottom {
    padding-bottom: 5px;
    border-bottom: 1px solid #787878;
}

.loadingoverlay {
    justify-content: center !important;
}
.loadingoverlay_text {
    font-size: 25px !important;
    margin-top: 20px !important;
    color: var(--color-primary) !important;
}

.bg-tr-cancelada,
.bg-tr-cancelada span,
.bg-tr-cancelada a:not(.btn),
.bg-tr-cancelada .text-primary {
    color: #9b9b9b !important;
}
.active.day{
    background-color: unset !important;
    color: unset !important;
}
.active.day.bloqueada{
    background: #000 !important;
    color: #FFF !important;
}
#calendario-cierres .datepicker table{
    width: 100%;
}
#content-imputs-rangue-block{
    max-height: 370px;
    overflow-y: auto;
}

#calendario-noches-container .daterangepicker {
    display: block !important;
    position: static;
    border: none;
}
#calendario-noches-container .daterangepicker::before {
    display: none;
}
#calendario-noches-container .drp-buttons {
    display: none;
}
#calendario-noches-container .daterangepicker .highlight-1 {
    background-color: #02294b;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-2 {
    background-color: #003A70;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-3 {
    background-color: #D77248;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-4 {
    background-color: #454545;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-5 {
    background-color: #5AB0E3;
    color: #FFF;
}