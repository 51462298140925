/*
 *  Document   : themes.css
 *  Author     : pixelcave
 *  Description: The themes stylesheet of the template. The classes can
 *  be used to alter the text or the background of an element individually
 *
 */

/*
=================================================================
THEME DEFAULT
=================================================================
*/

.themed-color-default {
    color: #5ccdde !important;
}

.themed-background-default {
    background-color: #5ccdde !important;
}

.themed-color-dark-default {
    color: #454e59 !important;
}

.themed-background-dark-default {
    background-color: #454e59 !important;
}

/*
=================================================================
THEME SOCIAL
=================================================================
*/

.themed-color-social {
    color: #627aad !important;
}

.themed-background-social {
    background-color: #627aad !important;
}

.themed-color-dark-social {
    color: #333333 !important;
}

.themed-background-dark-social {
    background-color: #333333 !important;
}

/*
=================================================================
THEME FLAT
=================================================================
*/

.themed-color-flat {
    color: #45a7b9 !important;
}

.themed-background-flat {
    background-color: #45a7b9 !important;
}

.themed-color-dark-flat {
    color: #374249 !important;
}

.themed-background-dark-flat {
    background-color: #374249 !important;
}

/*
=================================================================
THEME AMETHYST
=================================================================
*/

.themed-color-amethyst {
    color: #7c62ad !important;
}

.themed-background-amethyst {
    background-color: #7c62ad !important;
}

.themed-color-dark-amethyst {
    color: #353a4d !important;
}

.themed-background-dark-amethyst {
    background-color: #353a4d !important;
}

/*
=================================================================
THEME CREME
=================================================================
*/

.themed-color-creme {
    color: #b09573 !important;
}

.themed-background-creme {
    background-color: #b09573 !important;
}

.themed-color-dark-creme {
    color: #49443d !important;
}

.themed-background-dark-creme {
    background-color: #49443d !important;
}

/*
=================================================================
THEME PASSION
=================================================================
*/

.themed-color-passion {
    color: #de4b39 !important;
}

.themed-background-passion {
    background-color: #de4b39 !important;
}

.themed-color-dark-passion {
    color: #252d2f !important;
}

.themed-background-dark-passion {
    background-color: #252d2f !important;
}

/*
=================================================================
THEME CLASSY
=================================================================
*/

.themed-color-classy {
    color: #777777 !important;
}

.themed-background-classy {
    background-color: #777777 !important;
}

.themed-color-dark-classy {
    color: #353535 !important;
}

.themed-background-dark-classy {
    background-color: #353535 !important;
}