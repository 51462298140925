/*
 *  Document   : main.css
 *  Author     : pixelcave
 *  Description: The main stylesheet of the template
 *
 *  Structure (with shortcodes):
 *      (#m01mls) MAIN LAYOUT
 *      (#m02hds) HEADER
 *      (#m03sns) SIDEBAR/NAVIGATION
 *      (#m04mcs) MAIN CONTENT
 *      (#m05pgs) PAGES
 *      (#m06bos) BOOTSTRAP OVERWRITE/EXTEND STYLES
 *      (#m07hes) HELPERS
 *      (#m08ths) THEMES
 *      (#m09res) RESPONSIVE
 *      (#m10prs) PRINT
 */

/*
=================================================================
(#m01mls) MAIN LAYOUT
=================================================================
*/

/* Include Lato font from Google Web Fonts */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400italic,500,500italic,600,600italic,700,700italic');

/* Preloader */
#page-wrapper.page-loading {
    overflow: hidden;
}

#page-wrapper.page-loading .preloader {
    display: block;
}

#page-wrapper.page-loading #page-container {
    opacity: 0;
}

.preloader {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 99999;
}

.preloader .inner {
    position: fixed;
    top: 20%;
    left: 50%;
    margin: 0 0 0 -50px;
    width: 100px;
    height: 100px;
    text-align: center;
}

.preloader-spinner {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-color: #cccccc;
    -webkit-animation: rotateplane .8s infinite ease-in-out;
    animation: rotateplane .8s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    } 50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    } 100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/* Main Structure */
body {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #454e59;
    background-color: #454e59;
}

#login-container {
    position: absolute;
    width: 300px;
    top: 30px;
    left: 50%;
    margin-left: -150px;
    z-index: 1000;
}

#page-container,
#main-container {
    min-width: 320px;
}

#page-container {
    opacity: 1;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    overflow-x: hidden;
    -webkit-transition: background-color .2s ease-out, opacity .3s ease-out;
    transition: background-color .2s ease-out, opacity .3s ease-out;
}

#page-content {
    padding: 10px 5px 1px;
    background-color: #ebeef2;
}

#page-content-sidebar {
    padding: 10px;
    margin: -10px -5px 10px;
    background-color: #ffffff;
    border-bottom: 2px solid #dae0e8;
}

#page-container,
#sidebar {
    background-color: #454e59;
}

#sidebar,
#sidebar-alt,
#sidebar-alt-close,
#main-container,
header.navbar.navbar-fixed-top,
header.navbar.navbar-fixed-bottom {
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

#sidebar,
#sidebar-alt,
#sidebar-alt-close {
    z-index: 1029;
}

/* Alternative Sidebar */
#sidebar-alt,
#sidebar-alt-close {
    background: rgba(69, 78, 89, .9);
}

#sidebar-alt {
    position: fixed;
    top: 50px;
    right: -220px;
    bottom: 0;
    width: 220px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

#page-container.header-fixed-bottom #sidebar-alt {
    top: 0;
    bottom: 50px;
}

#sidebar-alt-close {
    display: inline-block;
    position: fixed;
    right: -30px;
    top: 50px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    opacity: 0;
}

#page-container.header-fixed-top #sidebar-alt-close,
#page-container.header-fixed-bottom #sidebar-alt-close {
    display: none !important;
}

#page-container.sidebar-alt-visible-xs #sidebar-alt {
    -webkit-transform: translateX(-220px);
    transform: translateX(-220px);
}

#page-container.sidebar-alt-visible-xs #sidebar-alt-close {
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px);
    opacity: 1;
}

/* Sidebar + Static Layout */
#sidebar {
    position: absolute;
    overflow: hidden;
    width: 220px;
    left: -220px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

#page-container.sidebar-visible-xs #sidebar,
#page-container.sidebar-visible-xs #main-container {
    -webkit-transform: translateX(220px);
    transform: translateX(220px);
}

/* Sidebar + Fixed Layout */
#page-container.header-fixed-top #page-content {
    padding-top: 60px;
}

#page-container.header-fixed-bottom #page-content {
    padding-bottom: 60px;
}

#page-container.header-fixed-top #sidebar {
    position: fixed;
    top: 50px;
    bottom: 0;
}

#page-container.header-fixed-bottom #sidebar {
    position: fixed;
    top: 0;
    bottom: 50px;
}

#page-container.header-fixed-top.sidebar-visible-xs #main-container,
#page-container.header-fixed-bottom.sidebar-visible-xs #main-container {
    -webkit-transform: none;
    transform: none;
}

#page-container.header-fixed-top #sidebar-extra-info,
#page-container.header-fixed-bottom #sidebar-extra-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

#page-container.header-fixed-top #sidebar-brand,
#page-container.header-fixed-bottom #sidebar-brand {
    display: none;
}

/* Fixed Width Layout */
#page-container.fixed-width {
    position: relative;
    max-width: 1280px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

#page-container.fixed-width #sidebar-alt,
#page-container.fixed-width #sidebar-alt-close {
    background: rgba(25, 25, 25, .8) !important;
}

/*
=================================================================
(#m02hds) HEADER
=================================================================
*/

header.navbar-default,
header.navbar-inverse {
    padding: 0;
    margin: 0;
    min-width: 320px;
    border: none;
}

/* Header Nav */
.navbar-default .navbar-nav > li > a {
    color: #454e59;
}

.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
    color: #ffffff;
    background-color: #5ccdde;
}

.navbar-inverse .collapse.in .navbar-nav .open .dropdown-menu > li > a {
    color: #dddddd;
}

.nav.navbar-nav-custom {
    float: left;
    margin: 0;
}

.nav.navbar-nav-custom > li {
    min-height: 50px;
    float: left;
}

.nav.navbar-nav-custom > li > a {
    min-width: 50px;
    padding: 5px 7px;
    line-height: 40px;
    text-align: center;
    color: #454e59;
    position: relative;
}

.nav.navbar-nav-custom > li > a .gi,
.nav.navbar-nav-custom > li > a .hi,
.nav.navbar-nav-custom > li > a .si,
.nav.navbar-nav-custom > li > a .fi {
    margin-top: -3px;
}

.nav.navbar-nav-custom > li > a .label-indicator {
    position: absolute;
    top: 4px;
    right: 4px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    font-weight: 600;
    border-radius: 10px;
}

.navbar-inverse .nav.navbar-nav-custom > li > a {
    color: #ffffff;
}

.nav.navbar-nav-custom > li.open > a,
.nav.navbar-nav-custom > li > a:hover,
.nav.navbar-nav-custom > li > a:focus {
    background-color: #5ccdde;
    color: #ffffff;
}

.navbar-inverse .nav.navbar-nav-custom > li.open > a,
.navbar-inverse .nav.navbar-nav-custom > li > a:hover,
.navbar-inverse .nav.navbar-nav-custom > li > a:focus {
    background: rgba(0, 0, 0, .15);
    color: #ffffff;
}

.nav.navbar-nav-custom > li > a > img {
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(255, 255, 255, .75);
    border-radius: 20px;
    vertical-align: top;
}

.nav.navbar-nav-custom .dropdown-menu {
    margin-top: 0;
}

/* Header Search */
.navbar-form {
    margin: 7px 0;
}

.collapsing .navbar-form,
.collapse.in .navbar-form {
    margin-left: -15px;
    margin-right: -15px;
}

.collapsing .navbar-form .form-group,
.collapse.in .navbar-form .form-group {
    margin-bottom: 0;
}

.navbar-form-custom {
    margin: 0;
    padding: 5px 8px;
    width: 140px;
}

.navbar-form-custom .form-control {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border: none;
    background: #ffffff;
    border-radius: 20px;
    opacity: .5;
}

.navbar-default .navbar-form-custom .form-control {
    background: #eeeeee;
}

.navbar-form-custom .form-control:hover,
.navbar-form-custom .form-control:focus {
    opacity: 1;
}

/*
=================================================================
(#m03sns) SIDEBAR/NAVIGATION
=================================================================
*/

/* Sidebar Content */
.sidebar-content {
    width: 220px;
    color: #acb5bf;
}

.sidebar-section {
    padding: 10px 15px;
}

#sidebar-extra-info {
    padding: 7px 15px 10px;
}

/* Sidebar Title */
.sidebar-title {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    margin: 0;
    font-weight: 300;
    font-size: 18px;
    display: block;
    color: #ffffff;
    background-color: #5ccdde;
    -webkit-transition: background .2s ease-out;
    transition: background .2s ease-out;
}

a.sidebar-title:hover,
a.sidebar-title:focus {
    background: rgba(0, 0, 0, .15);
    color: #ffffff;
    text-decoration: none;
}

.sidebar-title i {
    width: 18px;
    margin-right: 8px;
    opacity: .75;
}

/* Sidebar Navigation */
.sidebar-nav {
    list-style: none;
    margin: 0;
    padding: 10px 0 0;
}

.sidebar-separator {
    padding: 5px 0;
    color: #ffffff;
    text-align: center;
}

.sidebar-separator i {
    opacity: .1;
}

#sidebar-toggle-mini {
    display: none;
}

.sidebar-nav-ripple {
    display: block;
    position: absolute;
    background: rgba(92, 205, 222, .3);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.sidebar-nav-ripple.animate {
    -webkit-animation: ripple 1s ease-out;
    animation: ripple 1s ease-out;
}

@-webkit-keyframes ripple {
    100% {
        opacity: 0;
        -webkit-transform: scale(3);
    }
}

@keyframes ripple {
    100% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3);
    }
}

.sidebar-nav a {
    display: block;
    color: #acb5bf;
    padding: 0 10px;
    min-height: 40px;
    line-height: 40px;
    overflow: hidden;
    position: relative;
}

.sidebar-nav a:hover,
.sidebar-nav a:focus,
.sidebar-nav a.active,
.sidebar-nav a.open,
.sidebar-nav li.active > a {
    color: #ffffff;
    text-decoration: none;
}

.sidebar-nav a.open,
.sidebar-nav li.active > a {
    background: rgba(0, 0, 0, .2);
}

.sidebar-nav a.active {
    background: #ffffff;
    color: #454e59;
    border-right: 4px solid #5ccdde;
}

.sidebar-nav a > .sidebar-nav-icon {
    margin-right: 10px;
}

.sidebar-nav a > .sidebar-nav-icon,
.sidebar-nav a > .sidebar-nav-indicator {
    display: inline-block;
    opacity: .5;
    width: 18px;
    font-size: 14px;
    text-align: center;
}

.sidebar-nav a > .sidebar-nav-indicator {
    float: right;
    line-height: inherit;
    margin-left: 4px;
    font-size: 10px;
    -webkit-transition: -webkit-transform .15s ease-out;
    transition: transform .15s ease-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.sidebar-nav a:hover,
.sidebar-nav a:hover > .sidebar-nav-icon,
.sidebar-nav a:hover > .sidebar-nav-indicator,
.sidebar-nav a.active,
.sidebar-nav a.active > .sidebar-nav-icon,
.sidebar-nav a.active > .sidebar-nav-indicator,
.sidebar-nav a.open,
.sidebar-nav a.open > .sidebar-nav-icon,
.sidebar-nav a.open > .sidebar-nav-indicator,
.sidebar-nav li.active > a,
.sidebar-nav li.active > a > .sidebar-nav-icon,
.sidebar-nav li.active > a > .sidebar-nav-indicator {
    opacity: 1;
}

.sidebar-nav a.active > .sidebar-nav-indicator,
.sidebar-nav a.open > .sidebar-nav-indicator,
.sidebar-nav li.active > a > .sidebar-nav-indicator {
    -webkit-transform: rotate(-90deg) rotateZ(0deg);
    transform: rotate(-90deg) rotateZ(0deg);
}

.sidebar-nav ul {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    -webkit-transition: background .25s ease-out;
    transition: background .25s ease-out;
}

.sidebar-nav .active > ul,
.sidebar-nav .open + ul {
    display: block;
    background: rgba(255, 255, 255, .1);
}

.sidebar-nav ul a {
    margin: 0 0 0 19px;
    padding-left: 19px;
    border-left: 1px solid #454e59;
    min-height: 34px;
    line-height: 34px;
    font-weight: normal;
}

.sidebar-nav ul a.open,
.sidebar-nav ul li.active > a {
    background: none;
}

.sidebar-nav ul ul {
    margin: 0 0 0 19px;
}

.sidebar-nav ul ul a {
    margin: 0;
    padding-left: 19px;
    font-size: 13px;
    min-height: 32px;
    line-height: 32px;
}

.sidebar-nav ul .active > ul,
.sidebar-nav ul .open + ul {
    background-color: #454e59;
}

/* Sidebar Light Mode */
#page-container.sidebar-light {
    background-color: #ffffff;
}

#page-container.sidebar-light .content-header {
    background: rgba(255, 255, 255, .75);
}

#page-container.sidebar-light #page-content-sidebar {
    padding-left: 9px;
}

#page-container.sidebar-light .sidebar-themes li.active a {
    border: 2px solid #333333;
}

#page-container.sidebar-light #sidebar {
    background-color: #ffffff;
}

#page-container.sidebar-light #sidebar .sidebar-content {
    color: #999999;
}

#page-container.sidebar-light #sidebar .sidebar-separator {
    color: #000000;
}

#page-container.sidebar-light #sidebar .sidebar-nav a {
    color: #7b7b7b;
}

#page-container.sidebar-light #sidebar .sidebar-nav a:hover,
#page-container.sidebar-light #sidebar .sidebar-nav a:focus,
#page-container.sidebar-light #sidebar .sidebar-nav a.active,
#page-container.sidebar-light #sidebar .sidebar-nav a.open,
#page-container.sidebar-light #sidebar .sidebar-nav li.active > a {
    color: #4b4b4b;
}

#page-container.sidebar-light #sidebar .sidebar-nav a.open,
#page-container.sidebar-light #sidebar .sidebar-nav li.active > a {
    background: rgba(0, 0, 0, .1);
}

#page-container.sidebar-light #sidebar .sidebar-nav a.active {
    background-color: #f9f9f9;
}

#page-container.sidebar-light #sidebar .sidebar-nav .active > ul,
#page-container.sidebar-light #sidebar .sidebar-nav .open + ul {
    background: rgba(0, 0, 0, .04);
}

#page-container.sidebar-light #sidebar .sidebar-nav ul a {
    border-left-color: #cbcbcb;
}

/* Sidebar Color Themes */
.sidebar-themes {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    opacity: .35;
    transition: opacity .15s ease-out;
}

.sidebar-themes:hover {
    opacity: 1;
}

.sidebar-themes li {
    float: left;
    margin: 0 6px 10px 0;
}

.sidebar-themes li a {
    position: relative;
    display: block;
    width: 21px;
    height: 21px;
    overflow: hidden;
}

.sidebar-themes li a:hover {
    border: 2px solid rgba(255, 255, 255, .66);
}

.sidebar-themes li.active a {
    border: 2px solid #ffffff;
}

.sidebar-themes .section-header,
.sidebar-themes .section-side,
.sidebar-themes .section-content {
    position: absolute;
    display: block;
}

.sidebar-themes .section-side {
    top: 8px;
    right: 8px;
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
}

.sidebar-themes .section-header {
    top: 0;
    right: 0;
    bottom: 9px;
    left: 9px;
    background-color: #ffffff;
}

.sidebar-themes .section-content {
    top: 8px;
    right: 0;
    bottom: 0;
    left: 9px;
    background-color: #dddddd;
}

/* Toggle Menu */
.toggle-menu,
.toggle-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.toggle-menu a {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 5px 10px;
    color: #454e59;
    border-radius: 3px;
}

.toggle-menu a:hover,
.toggle-menu a:focus,
.toggle-menu a.active {
    text-decoration: none;
    color: #5ccdde;
}

.toggle-menu > li > a {
    font-weight: bold;
}

.toggle-menu a.submenu > i {
    margin-right: 7px;
    -webkit-transition: -webkit-transform .1s ease-out;
    transition: transform .1s ease-out;
}

.toggle-menu ul {
    display: none;
}

.toggle-menu ul a {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    font-size: 13px;
    font-weight: 400;
}

.toggle-menu ul ul a {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 30px;
}

.toggle-menu .open > a.submenu > i {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
}

.toggle-menu .open > ul {
    display: block;
}

/*
=================================================================
(#m04mcs) MAIN CONTENT
=================================================================
*/

/* Content Header */
.content-header {
    background-color: #ffffff;
    border-bottom: 2px solid #dae0e8;
    margin: -10px -5px 10px;
    padding: 0 15px;
}

.content-header h1,
.content-header h2 {
    margin: 5px 0;
    line-height: 28px;
}

.content-header h1 {
    font-weight: 500;
    font-size: 24px;
}

.content-header h2 {
    font-size: 18px;
}

.content-header small {
    font-size: 14px;
    font-weight: 400;
}

.header-section {
    padding: 15px 0;
}

.content-header-media {
    position: relative;
    height: 260px;
    overflow: hidden;
}

.content-header-media .header-section {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    color: #ffffff;
    background: rgba(0, 0, 0, .55);
}

.content-header-media > img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2560px;
    height: 260px;
    margin-left: -1280px;
}

.content-header-media small {
    color: #cccccc;
}

/* Blocks */
.block {
    margin: 0 0 10px;
    padding: 20px 15px 1px;
    background-color: #ffffff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    -webkit-box-shadow: 0 2px 0 rgba(218, 224, 232, .5);
    box-shadow: 0 2px 0 rgba(218, 224, 232, .5);
}

.block .block {
    border: 1px solid #dae0e8;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.block.full {
    padding: 20px 15px;
}

.block .block-content-full {
    margin: -20px -15px -1px;
}

.block .block-content-mini-padding {
    padding: 8px;
}

.block.full .block-content-full {
    margin: -20px -15px;
}

.block-title {
    margin: -20px -15px 20px;
    border-bottom: 2px solid #dae0e8;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background: rgba(218, 224, 232, .15);
}

.block-title h1,
.block-title h2,
.block-title h3,
.block-title h4,
.block-title h5,
.block-title h6 {
    display: inline-block;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
    padding: 10px 15px 9px;
    font-weight: 600;
    text-transform: uppercase;
}

.block-title h1 small,
.block-title h2 small,
.block-title h3 small,
.block-title h4 small,
.block-title h5 small,
.block-title h6 small {
    font-size: 14px;
    font-weight: 600;
}

.block-title .nav-tabs {
    padding: 0;
    margin: 0;
    border-bottom: none;
}

.block-title .nav-tabs > li > a {
    border: none;
    min-height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0;
}

.block-title .nav-tabs > li > a:hover {
    background-color: #ffffff;
}

.block-title .nav-tabs > li.active > a,
.block-title .nav-tabs > li.active > a:hover,
.block-title .nav-tabs > li.active > a:focus {
    border: none;
    background-color: #dae0e8;
}

.block-options {
    padding: 2px 3px;
    height: 38px;
}

.block-options .label {
    display: inline-block;
    padding: 6px;
    vertical-align: middle;
    font-size: 14px;
    margin-top: 4px;
}

.block-options .dropdown-menu {
    margin-top: 3px;
}

.block-options .btn {
    padding-right: 8px;
    padding-left: 8px;
    min-width: 34px;
    text-align: center;
    color: #454e59;
    opacity: .4;
    background: transparent;
    border-color: transparent;
    -webkit-transition: opacity .15s ease-out;
    transition: opacity .15s ease-out;
}

.block-options .btn:hover,
.block-options .btn:active,
.block-options .btn.active,
.block-options .btn:focus {
    opacity: 1;
}

.block-options .btn i {
    font-size: 14px;
}

.block-section {
    margin-bottom: 20px;
}

/* Widgets */
.widget {
    background-color: #ffffff;
    margin-bottom: 10px;
    position: relative;
    border-radius: 2px;
}

.widget > .widget-content:first-child,
.widget > .widget-image:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}

.widget > .widget-content:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

a.widget,
a.widget-content {
    display: block;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
}

a.widget:hover,
a.widget:focus,
a.widget-content:hover,
a.widget-content:focus {
    text-decoration: none;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
}

a.widget:active,
a.widget-content:active {
    text-decoration: none;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .02);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .02);
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.widget-image {
    height: 310px;
    overflow: hidden;
    position: relative;
}

.widget-image > img {
    min-width: 787px;
}

.widget-image.widget-image-bottom img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.widget-image.widget-image-sm {
    height: 270px;
}

.widget-image.widget-image-xs {
    height: 175px;
}

.widget-image > i {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: #ffffff;
    font-size: 70px;
    opacity: .5;
}

.widget-image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 25px;
    background: rgba(0, 0, 0, .65);
}

.widget-content {
    padding: 15px;
}

.widget-content.widget-content-mini {
    padding-top: 7px;
    padding-bottom: 7px;
}

.widget-content.widget-content-full {
    padding: 0;
}

.widget-content.widget-content-full-top-bottom {
    padding-top: 0;
    padding-bottom: 0;
}

.widget-content .pull-left {
    margin-right: 20px;
}

.widget-content .pull-right {
    margin-left: 20px;
}

a.widget-content {
    display: block;
}

a.widget-content {
    display: block;
}

.widget-heading {
    margin: 10px 0;
}

.widget-icon {
    display: inline-block;
    width: 64px;
    height: 64px;
    line-height: 60px;
    margin: 5px;
    font-size: 28px;
    text-align: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, .05);
}

.widget-icon.pull-left {
    margin: 5px 5px 5px 0;
}

.widget-icon.pull-right {
    margin: 5px 0 5px 5px;
}

.widget-icon .gi,
.widget-icon .hi,
.widget-icon .si,
.widget-icon .fi {
    margin-top: -3px;
}

/* Full Background Image */
img.full-bg {
    min-height: 100%;
    min-width: 1280px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}

img.full-bg.full-bg-bottom {
    top: auto;
    bottom: 0;
}

@media screen and (max-width: 1280px) {
    img.full-bg {
        left: 50%;
        margin-left: -640px;
    }
}

/* Content which contains floats */
.content-float .pull-left {
    margin: 0 20px 20px 0;
}

.content-float .pull-right {
    margin: 0 0 20px 20px;
}

.content-float-hor .pull-left {
    margin-right: 20px;
}

.content-float-hor .pull-right {
    margin-left: 20px;
}

/*
=================================================================
(#m05pgs) PAGES
=================================================================
*/

/* Calendar */
.calendar-events {
    list-style: none;
    margin: 0;
    padding: 0;
}

.calendar-events li {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 6px;
    padding: 10px 12px;
    border-radius: 3px;
    background-color: #555555;
    opacity: .8;
    -webkit-box-shadow: inset 2px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 2px 0 rgba(0, 0, 0, .15);
}

.calendar-events li i {
    margin-right: 10px;
}

.calendar-events li:hover {
    cursor: move;
    opacity: 1;
}

/* Gallery */
.gallery img, a[data-toggle="lightbox-image"] img {
    max-width: 100%;
}

a[data-toggle="lightbox-image"],
a.gallery-link {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

a[data-toggle="lightbox-image"]:hover img {
    opacity: .75;
}

.gallery-image-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.gallery-image-options {
    padding: 30px 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    -webkit-transform: scale(.75);
    transform: scale(.75);
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.gallery-image-options:hover,
.gallery-image-options:focus {
    text-decoration: none;
}

.gallery-image-container:hover .gallery-image-options {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* Media Items */
.media-items {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    background: rgba(255, 255, 255, .5);
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
}

.media-items:hover {
    background: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
}

.media-items:hover .media-items-options {
    display: block;
}

.media-items .media-items-options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    display: none;
}

.media-items .media-items-content {
    padding: 10px 0;
}

/* Charts */
.pie-chart .pie-avatar {
    position: absolute;
    top: 8px;
    left: 8px;
}

.chart-tooltip,
.mini-chart-tooltip {
    position: absolute;
    display: none;
    color: #ffffff;
    background: rgba(0, 0, 0, .75);
    padding: 4px 8px;
}

.chart-pie-label {
     font-size: 13px;
     text-align: center;
     padding: 4px 8px;
     color: #ffffff;
}

.mini-chart-tooltip {
    left: 0;
    top: 0;
    visibility: hidden;
}

.legend table td {
    padding: 3px 5px;
    font-size: 15px;
}

/* Error */
#error-container {
    padding: 30px;
    position: relative;
}

#error-container h1 {
    font-size: 72px;
    margin-bottom: 30px;
}

#error-container h2 {
    margin-bottom: 50px;
}

/* Timeline */
.timeline {
    position: relative;
}

.timeline-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.timeline-list li {
    position: relative;
    margin: 0;
    padding: 0 0 20px;
}

.timeline-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    background-color: #eeeeee;
    border-radius: 3px;
    z-index: 500;
}

.timeline-time {
    padding: 0 80px 0 20px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 15px;
    background-color: #f9f9f9;
}

.timeline-content {
    padding-left: 20px;
}

.block-content-full .timeline-content,
.widget-content-full .timeline-content {
    padding-right: 20px;
}

/* Chat UI */
.chatui.chatui-window.widget {
    position: fixed;
    bottom: 0;
    right: 50%;
    width: 300px;
    margin-right: -150px;
    margin-bottom: 0;
    z-index: 1028;
}

#page-container.header-fixed-bottom .chatui.chatui-window {
    bottom: 50px;
}

.chatui .chatui-action-close,
.chatui .chatui-action-open {
    display: none;
}

.chatui.chatui-window .chatui-action-close,
.chatui.chatui-window.chatui-window-close .chatui-action-open {
    display: inline;
}

.chatui.chatui-window.chatui-window-close .chatui-talk,
.chatui.chatui-window.chatui-window-close .chatui-form,
.chatui.chatui-window.chatui-window-close .chatui-action-close {
    display: none;
}

.chatui-talk {
    height: 250px;
    overflow-y: auto;
}

.chatui-talk ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.chatui-talk li {
    padding: 2px 0;
    margin-bottom: 5px;
}

.chatui-talk .chatui-talk-msg {
    min-height: 40px;
    padding: 10px 10px 10px 46px;
    background-color: #f6f6f6;
    border-radius: 25px;
    position: relative;
    margin-right: 15%;
}

.chatui-talk .chatui-talk-msg.chatui-talk-msg-right {
    margin-left: 15%;
    margin-right: 0;
}

.chatui-talk-msg .chatui-talk-msg-avatar {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
}

.chatui-form {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #eeeeee;
}

.chatui-form .form-control,
.chatui-form .form-control:focus {
    border-radius: 0;
    border: none;
}

/* Tasks */
.task-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.task-list li {
    position: relative;
    padding: 10px 40px 10px 15px;
    margin-bottom: 12px;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-transition: box-shadow .15s ease-out;
    transition: box-shadow .15s ease-out;
}

.task-list li:hover {
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
}

.task-list li.in-1x {
    margin-left: 20px;
}

.task-list li.in-2x {
    margin-left: 40px;
}

.task-list li > .task-close {
    position: absolute;
    top: 10px;
    right: 15px;
    opacity: 0;
    -webkit-transition: opacity .15s ease-out;
    transition: opacity .15s ease-out;
}

.task-list li:hover > .task-close {
    opacity: 1;
}

.task-list li.task-done {
    opacity: .5;
}

.task-list li.task-done > label {
    text-decoration: line-through;
}

.task-list li.task-done:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*
=================================================================
(#m06bos) BOOTSTRAP OVERWRITE/EXTEND STYLES
=================================================================
*/

/* Navbar */
.navbar.navbar-default.navbar-glass {
    background: rgba(255, 255, 255, .9);
}

.navbar.navbar-inverse.navbar-glass {
    background: rgba(92, 205, 222, .9);
}

.navbar.navbar-default,
.navbar.navbar-default.navbar-glass:hover {
    background: #ffffff;
    border-bottom: 1px solid #dae0e8;
}

.navbar.navbar-inverse,
.navbar.navbar-inverse.navbar-glass:hover {
    background: #5ccdde;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    border-width: 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small,
h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small,
.h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
    font-weight: 300;
    color: #879599;
}

h1, h2, h3 {
    margin-bottom: 15px;
}

.text-primary,
.text-primary:hover,
a,
a:hover,
a:focus,
a.text-primary,
a.text-primary:hover,
a.text-primary:focus {
    color: #5ccdde;
}

.text-danger,
.text-danger:hover,
a.text-danger,
a.text-danger:hover,
a.text-danger:focus {
    color: #de815c;
}

.text-warning,
.text-warning:hover,
a.text-warning,
a.text-warning:hover,
a.text-warning:focus {
    color: #deb25c;
}

.text-success,
.text-success:hover,
a.text-success,
a.text-success:hover,
a.text-success:focus {
    color: #afde5c;
}

.text-info,
.text-info:hover,
a.text-info,
a.text-info:hover,
a.text-info:focus {
    color: #5cafde;
}

.text-muted,
.text-muted:hover,
a.text-muted,
a.text-muted:hover,
a.text-muted:focus {
    color: #999999;
}

.text-light,
.text-light:hover,
a.text-light,
a.text-light:hover,
a.text-light:focus {
    color: #ffffff;
}

.text-dark,
.text-dark:hover,
a.text-dark,
a.text-dark:hover,
a.text-dark:focus {
    color: #454e59;
}

.text-black,
.text-black:hover,
a.text-black,
a.text-black:hover,
a.text-black:focus {
    color: #000000;
}

.text-light-op,
.text-light-op:hover,
a.text-light-op,
a.text-light-op:hover,
a.text-light-op:focus {
    color: rgba(255, 255, 255, .8);
}

.text-dark-op,
.text-dark-op:hover,
a.text-dark-op,
a.text-dark-op:hover,
a.text-dark-op:focus {
    color: rgba(0, 0, 0, .4);
}

b, strong {
    font-weight: 600;
}

ul,
ol {
    padding-left: 30px;
}

p {
    line-height: 1.7;
}

.well {
    background-color: #f9f9f9;
    border: 1px solid #eeeeee;
}

.page-header {
    margin: 30px 0;
    padding: 10px 0;
    border-bottom: 1px solid #dddddd;
}

.sub-header {
    margin: 20px 0;
    padding: 10px 0;
    border-bottom: 1px dashed #dddddd;
}

.inner-sidebar-header {
    margin: 20px 0 10px;
}

.inner-sidebar-header .pull-right,
.inner-sidebar-header .pull-left {
    margin-top: -2px;
}

blockquote {
    margin: 20px 0;
    padding: 30px 60px 30px 20px;
    position: relative;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 3px;
}

blockquote:before {
    display: block;
    content: "\201C";
    font-family: serif;
    font-size: 96px;
    position: absolute;
    right: 10px;
    top: -20px;
    color: #000000;
    opacity: .15;
}

blockquote.pull-right:before {
    left: 10px;
    right: auto;
}

article.article-story p {
    font-size: 18px;
    line-height: 1.8;
}

/* Forms */
fieldset legend {
    font-size: 18px;
    padding: 30px 0 10px;
    border-bottom: 2px solid #ebeef2;
}

input[type="file"] {
    padding-top: 7px;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control,
textarea.form-control {
    -webkit-appearance: none;
}

.form-control {
    padding: 6px 8px;
    max-width: 100%;
    margin: 1px 0;
    color: #454e59;
    border-color: #dae0e8;
    border-radius: 3px;
}

input[type="text"].form-control.input-lg,
input[type="password"].form-control.input-lg,
input[type="email"].form-control.input-lg {
    height: 45px;
}

.input-group {
    margin-top: 1px;
    margin-bottom: 1px;
}

.input-group .form-control {
    margin-top: 0;
}

.form-control:focus {
    border-color: #5ccdde;
}

.help-block {
    margin-top: 8px;
    color: #999999;
    font-weight: 400;
    font-style: italic;
}

.input-group-addon {
    min-width: 45px;
    text-align: center;
    background-color: #ffffff;
    border-color: #dae0e8;
}

.form-horizontal .control-label {
    margin-bottom: 5px;
}

.form-horizontal .control-label-fixed {
    padding-top: 5px;
    margin-bottom: 5px;
}

.form-bordered {
    margin: -15px -15px -1px;
}

.modal-body .form-bordered {
    margin-bottom: -20px;
}

.form-bordered fieldset legend {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.form-bordered .form-group {
    margin: 0;
    border: none;
    padding: 15px;
    border-bottom: 1px dotted #ebeef2;
}

.form-bordered .form-group.form-actions {
    background-color: #f9f9f9;
    border-bottom: none;
}

.form-horizontal.form-bordered .form-group {
    padding-left: 0;
    padding-right: 0;
}

.form-bordered .help-block {
    margin-bottom: 0;
}

.has-success .form-control,
.has-warning .form-control,
.has-error .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .help-block,
.has-success .control-label,
.has-success .input-group-addon,
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .radio,
.has-success .radio-inline {
    color: #afde5c;
}

.has-success .form-control,
.has-success .input-group-addon {
    border-color: #afde5c;
    background-color: #ffffff;
}

.has-success .form-control:focus {
    border-color: #7fb364;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .input-group-addon,
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .radio,
.has-warning .radio-inline {
    color: #deb25c;
}

.has-warning .form-control,
.has-warning .input-group-addon {
    border-color: #deb25c;
    background-color: #ffffff;
}

.has-warning .form-control:focus {
    border-color: #e58f5a;
}

.has-error .help-block,
.has-error .control-label,
.has-error .input-group-addon,
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .radio,
.has-error .radio-inline {
    color: #de815c;
}

.has-error .form-control,
.has-error .input-group-addon {
    border-color: #de815c;
    background-color: #ffffff;
}

.has-error .form-control:focus {
    border-color: #cc7872;
}

.form-control.form-control-borderless,
.form-control.form-control-borderless:focus {
    border: none;
}

/* Form Select Switches */
.switch {
    margin: 1px 0;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.switch input {
    position: absolute;
    opacity: 0;
}

.switch span {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    border-radius: 14px;
    background: rgba(0, 0, 0, .2);
    -webkit-transition: background-color .75s;
    transition: background-color .75s;
}

.switch span:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    width: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

.switch input:checked + span:after {
    left: 27px;
    width: 24px;
    top: 2px;
    bottom: 2px;
}

.switch input:checked + span {
    background-color: #eeeeee;
}

.switch-default input:checked + span {
    background-color: #dae0e8;
}

.switch-warning input:checked + span {
    background-color: #deb25c;
}

.switch-danger input:checked + span {
    background-color: #de815c;
}

.switch-info input:checked + span {
    background-color: #5cafde;
}

.switch-success input:checked + span {
    background-color: #afde5c;
}

.switch-primary input:checked + span {
    background-color: #5ccdde;
}

/* Form CSS Checkboxes */
.csscheckbox {
    margin: 1px 0;
    padding: 7px 0;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
}

.csscheckbox input {
    position: absolute;
    opacity: 0;
}

.csscheckbox span {
    position: relative;
    display: inline-block;
    margin-top: -2px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: rgba(250, 250, 250, .25);
    border: 1px solid #dae0e8;
    vertical-align: middle;
}

.csscheckbox span:hover {
    background: rgba(250, 250, 250, .75);
}

.csscheckbox span:after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out;
    -webkit-transform: scale(.5);
    transform: scale(.5);
}

.csscheckbox input:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.csscheckbox input:checked + span:after {
    background-color: #eeeeee;
}

.csscheckbox-default input:checked + span:after {
    background-color: #dae0e8;
}

.csscheckbox-warning input:checked + span:after {
    background-color: #deb25c;
}

.csscheckbox-danger input:checked + span:after {
    background-color: #de815c;
}

.csscheckbox-info input:checked + span:after {
    background-color: #5cafde;
}

.csscheckbox-success input:checked + span:after {
    background-color: #afde5c;
}

.csscheckbox-primary input:checked + span:after {
    background-color: #5ccdde;
}

/* Tables */
.table.table-vcenter th,
.table.table-vcenter td {
    vertical-align: middle;
}

.table thead > tr > th {
    font-size: 18px;
    font-weight: 600;
}

.table thead > tr > th > small {
    font-weight: 400;
    font-size: 75%;
}

.table thead > tr > th,
.table thead > tr > td,
.table tfoot > tr > th,
.table tfoot > tr > td {
    padding-top: 15px;
    padding-bottom: 15px;
}

.table tbody > tr > th,
.table tbody > tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
}

.table.table-condensed tbody > tr > th,
.table.table-condensed tbody > tr > td {
    padding-top: 3px;
    padding-bottom: 3px;
}

.table tfoot > tr > th,
.table tfoot > tr > td {
    background-color: #f9f9f9;
}

.table-borderless tbody > tr > th,
.table-borderless tbody > tr > td {
    border-top-width: 0;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td,
.table tbody + tbody,
.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border-color: #ebeef2;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: #ebeef2;
}

.td-label {
    border-left-width: 3px;
    border-left-style: solid;
}

.td-label-danger {
    border-left-color: #de815c !important;
}

.td-label-warning {
    border-left-color: #deb25c !important;
}

.td-label-info {
    border-left-color: #5cafde !important;
}

.td-label-success {
    border-left-color: #afde5c !important;
}

.td-label-muted {
    border-left-color: #cccccc !important;
}

.table.table-pricing {
    background-color: #ffffff;
    text-align: center;
    border: 2px solid #ffffff;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out;
}

.table.table-pricing.table-featured,
.table.table-pricing:hover {
    border-color: #5ccdde;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

.table.table-pricing.table-pricing-nohover:hover {
    border-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.table.table-pricing th {
    text-align: center;
    background-color: #5ccdde;
    border-bottom: none;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.table.table-pricing td {
    padding-top: 12px;
    padding-bottom: 12px;
}

/* List Group */
.list-group-item {
    border-color: #ebeef2;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    background-color: #5ccdde;
    border-color: #5ccdde;
}

a.list-group-item.active .list-group-item-text,
a.list-group-item.active:hover .list-group-item-text,
a.list-group-item.active:focus .list-group-item-text {
    color: #ffffff;
}

a.list-group-item:hover,
a.list-group-item:focus {
    background-color: #f9f9f9;
}

a.list-group-item.active > .badge {
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;
}

/* Navs */
.nav-pills > li > a {
    color: #454e59;
    border-radius: 3px;
}

.nav-pills i.icon-push {
    margin-right: 8px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    color: #ffffff;
    background-color: #5ccdde;
}

.nav > li i {
    font-size: 14px;
}

.nav-pills > .active > a > .badge {
    color: #5ccdde;
}

.nav-pills.nav-icons > li.active > a i,
.nav-pills.nav-icons > li.active > a:hover i,
.nav-pills.nav-icons > li.active > a:focus i {
    color: #ffffff;
}

.nav-stacked > li > a {
    margin: 4px 0 0;
}

.nav .caret,
.nav a:hover .caret,
.nav a:focus .caret {
    border-top-color: #5ccdde;
    border-bottom-color: #5ccdde;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: #f9f9f9;
}

.nav-tabs {
    border-bottom-color: #ebeef2;
}

.nav-tabs > li {
    margin-bottom: 0;
}

.nav-tabs > li > a {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: -1px;
    font-weight: 500;
    color: #454e59;
}

.nav-tabs > li > a:hover {
    border-color: #ebeef2;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #454e59;
    border-color: #ebeef2;
    border-bottom-color: transparent;
}

.nav-pills > li.active > a > .badge {
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

.nav-users {
    list-style: none;
    margin: 0;
    padding-left: 5px;
}

.nav-users-online,
.nav-users-away,
.nav-users-offline {
    border-left-width: 2px;
    border-left-style: solid;
}

.nav-users-online {
    border-left-color: #afde5c;
}

.nav-users-away {
    border-left-color: #deb25c;
}

.nav-users-offline {
    border-left-color: #de815c;
}

.nav-users-offline a {
    opacity: .5;
}

.nav-users a {
    display: block;
    min-height: 56px;
    padding: 8px 10px 8px 60px;
    position: relative;
    color: #454e59;
    border-radius: 3px;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
}

.nav-users a:hover,
.nav-users a:focus {
    text-decoration: none;
    opacity: 1;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
}

.nav-users a:active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .02);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .02);
}

.nav-users-avatar {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, .2);
    border-radius: 50%;
}

.nav-users-indicator {
    position: absolute;
    top: 5px;
    left: 5px;
}

.nav-users-heading {
    display: block;
    margin-top: 2px;
    font-weight: 600;
}

.dropdown-menu {
    min-width: 180px;
    padding: 0;
    font-size: 14px;
    border-color: #dae0e8;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    display: block;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.dropdown-menu.dropdown-menu-right {
    -webkit-transform-origin: top right;
    transform-origin: top right;
}

.dropup .dropdown-menu {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
}

.dropup .dropdown-menu.dropdown-menu-right {
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
}

.open > .dropdown-menu,
.dropdown-menu.open {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.lt-ie10 .dropdown-menu {
    display: none;
}

.lt-ie10 .open > .dropdown-menu,
.lt-ie10 .dropdown-menu.open {
    display: block;
}

.dropdown-menu > li > a {
    padding: 8px 10px;
}

.dropdown-menu i {
    opacity: 0.2;
    line-height: 17px;
}

.dropdown-menu a:hover i {
    opacity: 0.5;
}

.dropdown-menu .divider {
    margin: 3px 0;
    padding: 0 !important;
    background-color: #f0f0f0;
}

li.dropdown-header {
    padding: 8px 10px;
    color: #454e59;
    background-color: #ebeef2;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -3px;
    margin-left: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.collapse.in .dropdown.open .dropdown-menu {
    display: block;
    position: static;
    margin: 0 0 0 15px;
    left: auto;
    top: auto;
}

.pagination > li > a,
.pagination > li > span  {
    font-weight: 500;
    color: #5ccdde;
    margin-left: 5px;
    margin-right: 5px;
    border: none !important;
    border-radius: 3px !important;
    background: transparent;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #5ccdde;
}

.pagination > .disabled >span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    background: transparent;
}

.pager > li > a,
.pager > li > span {
    border-color: #ebeef2;
}

.pager > li > a:hover,
.pagination > li > a:hover {
    background-color: #5ccdde;
    border-color: #5ccdde;
    color: #ffffff;
}

.pager > li.disabled > a:hover {
    border-color: #ebeef2;
}

/* Popover */
.popover {
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
}

.popover-title {
    background: none;
    border: none;
    font-size: 15px;
    font-weight: 600;
    padding-top: 7px;
    padding-bottom: 2px;
}

/* Tooltip */
.tooltip {
    z-index: 1051;
}

.tooltip.in {
    opacity: 1;
}

.tooltip-inner {
    padding: 4px 6px;
    background-color: #000000;
    color: #ffffff;
}

.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
    border-top-color: #000000;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #000000;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #000000;
}

.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: #000000;
}

/* Breadcrumps */
.breadcrumb {
    background-color: transparent;
}

.breadcrumb i {
    font-size: 14px;
}

.breadcrumb-top {
    font-size: 12px;
    text-align: right;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 7px;
}

/* Progress Bars */
.progress {
    background: rgba(0, 0, 0, .1);
}

.progress,
.progress-bar {
    height: 18px;
    line-height: 18px;
    font-weight: 600;
}

.progress.progress-mini,
.progress-bar.progress-mini,
.progress-mini .progress-bar {
    height: 7px;
    line-height: 7px;
}

.progress-bar-danger {
    background-color: #de815c;
}

.progress-bar-warning {
    background-color: #deb25c;
}

.progress-bar-success {
    background-color: #afde5c;
}

.progress-bar-info {
    background-color: #5cafde;
}

.progress-bar-primary {
    background-color: #5ccdde;
}

/* Modals */
.modal-content {
    border: none;
}

.modal-header,
.modal-header-tabs {
    padding: 17px 15px 15px;
    border-bottom: none;
    background-color: #f9f9f9;
}

.modal-header-tabs .nav-tabs {
    margin: 0 -15px -15px;
}

.modal-title {
    font-weight: 300;
}

.modal-body {
    padding: 20px 15px;
}

.modal-footer {
    margin-top: 0;
    padding: 9px 15px 10px;
    border-top: none;
    background-color: #f9f9f9;
}

.modal.fade .modal-dialog {
    opacity: 0;
    -webkit-transition: all .45s ease-out;
    transition: all .45s ease-out;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scale(.6);
    transform: scale(.6);
}

.modal.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.modal-backdrop.in {
    opacity: .75;
}

/* Buttons */
.btn {
    margin: 1px 0;
    background-color: #ffffff;
    border-radius: 3px;
}

.btn.btn-rounded {
    border-radius: 20px;
}

.btn.btn-square {
    border-radius: 0;
}

.btn-ripple {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, .5);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.btn-ripple.animate {
    -webkit-animation: ripple 1s ease-out;
    animation: ripple 1s ease-out;
}

.btn .gi,
.btn .hi,
.btn .si,
.btn .fi {
    line-height: 1;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: .4;
}

.block-options .btn,
.input-group .btn,
.modal-content .btn {
    margin-top: 0;
    margin-bottom: 0;
}

.btn-default {
    background-color: #eeeeee;
    border-color: #eeeeee;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.open .btn-default.dropdown-toggle,
.open .btn-default.dropdown-toggle:hover,
.open .btn-default.dropdown-toggle:focus,
.open .btn-default.dropdown-toggle.focus {
    background-color: #dddddd;
    border-color: #dddddd;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
.btn-default[disabled]:active:focus,
.btn-default[disabled].active:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active:focus,
fieldset[disabled] .btn-default.active:focus {
    background-color: #eeeeee;
    border-color: #eeeeee;
}

.btn-primary {
    background-color: #5ccdde;
    border-color: #5ccdde;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.open .btn-primary.dropdown-toggle,
.open .btn-primary.dropdown-toggle:hover,
.open .btn-primary.dropdown-toggle:focus,
.open .btn-primary.dropdown-toggle.focus {
    background-color: #02aac4;
    border-color: #02aac4;
    color: #ffffff !important;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
.btn-primary[disabled]:active:focus,
.btn-primary[disabled].active:focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active:focus,
fieldset[disabled] .btn-primary.active:focus {
    background-color: #5ccdde;
    border-color: #5ccdde;
}

.btn-danger {
    background-color: #de815c;
    border-color: #de815c;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger.active,
.btn-danger.active:hover,
.btn-danger.active:focus,
.open .btn-danger.dropdown-toggle,
.open .btn-danger.dropdown-toggle:hover,
.open .btn-danger.dropdown-toggle:focus,
.open .btn-danger.dropdown-toggle.focus {
    background-color: #c43902;
    border-color: #c43902;
    color: #ffffff !important;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
.btn-danger[disabled]:active:focus,
.btn-danger[disabled].active:focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger:active:focus,
fieldset[disabled] .btn-danger.active:focus {
    background-color: #de815c;
    border-color: #de815c;
}

.btn-warning {
    background-color: #deb25c;
    border-color: #deb25c;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning.active,
.btn-warning.active:hover,
.btn-warning.active:focus,
.open .btn-warning.dropdown-toggle,
.open .btn-warning.dropdown-toggle:hover,
.open .btn-warning.dropdown-toggle:focus,
.open .btn-warning.dropdown-toggle.focus {
    background-color: #de9502;
    border-color: #de9502;
    color: #ffffff !important;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
.btn-warning[disabled]:active:focus,
.btn-warning[disabled].active:focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning:active:focus,
fieldset[disabled] .btn-warning.active:focus {
    background-color: #c48402;
    border-color: #c48402;
}

.btn-success {
    background-color: #afde5c;
    border-color: #afde5c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success.active,
.btn-success.active:hover,
.btn-success.active:focus,
.open .btn-success.dropdown-toggle,
.open .btn-success.dropdown-toggle:hover,
.open .btn-success.dropdown-toggle:focus,
.open .btn-success.dropdown-toggle.focus {
    background-color: #7dc402;
    border-color: #7dc402;
    color: #ffffff !important;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
.btn-success[disabled]:active:focus,
.btn-success[disabled].active:focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success:active:focus,
fieldset[disabled] .btn-success.active:focus {
    background-color: #afde5c;
    border-color: #afde5c;
}

.btn-info {
    background-color: #5cafde;
    border-color: #5cafde;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info:active:hover,
.btn-info:active:focus,
.btn-info.active,
.btn-info.active:hover,
.btn-info.active:focus,
.open .btn-info.dropdown-toggle,
.open .btn-info.dropdown-toggle:hover,
.open .btn-info.dropdown-toggle:focus,
.open .btn-info.dropdown-toggle.focus {
    background-color: #027dc4;
    border-color: #027dc4;
    color: #ffffff !important;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
.btn-info[disabled]:active:focus,
.btn-info[disabled].active:focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info:active:focus,
fieldset[disabled] .btn-info.active:focus {
    background-color: #5cafde;
    border-color: #5cafde;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
    color: #5ccdde;
}

/* Panels */
.panel {
    margin-bottom: 20px;
}

.panel-heading {
    padding: 15px;
}

.panel-title {
    font-size: 14px;
}

.panel-default > .panel-heading {
    background-color: #f9f9f9;
}

.panel-group {
    margin-bottom: 20px;
}

/* Pre, Code */
pre {
    background: #151515;
    overflow: scroll;
}

code {
    border: 1px solid #fad4df;
    margin: 1px 0;
    display: inline-block;
}

.btn code {
    display: inline;
    margin: 0;
}

/* Alerts */
.alert {
    color: #ffffff;
    border-width: 0;
    border-radius: 3px;
}

.alert .alert-link {
    color: #000000;
    opacity: .4;
}

.alert-danger {
    background-color: #de815c;
}

.alert-warning {
    background-color: #deb25c;
}

.alert-success {
    background-color: #afde5c;
}

.alert-info {
    background-color: #5cafde;
}

.alert-dismissable .close {
    top: -8px;
    right: -26px;
    color: #000000;
}

.close {
    opacity: .25;
    text-shadow: none;
}

.close:hover,
.close:focus {
    opacity: .5;
}

/* Labels, Badges */
.label,
.badge {
    font-weight: normal;
    font-size: 90%;
}

.label {
    padding: 1px 4px;
}

.badge {
    background: rgba(0, 0, 0, .3);
    padding: 3px 6px;
}

.label-danger,
.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #de815c;
}

.label-warning,
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #deb25c;
}

.label-success,
.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #afde5c;
}

.label-info,
.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #5cafde;
}

.label-primary,
.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #5ccdde;
}

.label-default,
.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #999999;
}

.label-danger[href]:hover,
.label-danger[href]:focus,
.label-warning[href]:hover,
.label-warning[href]:focus,
.label-success[href]:hover,
.label-success[href]:focus,
.label-info[href]:hover,
.label-info[href]:focus,
.label-primary[href]:hover,
.label-primary[href]:focus,
.label-default[href]:hover,
.label-default[href]:focus {
    opacity: .75;
}

/* Carousel */
.carousel-control {
    max-width: 50px;
}

.carousel-control.left,
.carousel-control.right {
    background: none;
    opacity: 0;
    filter: none;
}

.carousel:hover .carousel-control.left,
.carousel:hover .carousel-control.right {
    opacity: 1;
}

.carousel-control.left:hover,
.carousel-control.right:hover {
    background: rgba(0, 0, 0, .2);
}

.carousel-control span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control i {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
}

.carousel-indicators,
.carousel-caption {
    bottom: 5px;
}

.carousel-caption {
    text-shadow: 0 5px 15px #000000;
}

/* Thumbnail */
.img-thumbnail-transparent {
    background-color: rgba(255, 255, 255, .25);
    border-color: transparent;
}

.img-thumbnail-avatar {
    width: 69px !important;
    height: 69px !important;
}

.img-thumbnail-avatar-2x {
    width: 133px !important;
    height: 133px !important;
}

/* Bottom Margin */
p, .table, .alert, .carousel {
    margin-bottom: 20px;
}

/* Removing shadows and radius */
.navbar-form,
.navbar-collapse,
.form-control,
.form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus,
.progress,
.progress-bar,
.panel,
.modal-content {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar,
.dropdown-menu,
.tooltip-inner,
.breadcrumb,
.modal-content,
.panel-group .panel {
    border-radius: 0;
}

/*
=================================================================
(#m07hes) HELPERS
=================================================================
*/


.border-top {
    border-top: 1px solid #eeeeee;
}

.border-right {
    border-right: 1px solid #eeeeee;
}

.border-bottom {
    border-bottom: 1px solid #eeeeee;
}

.border-left {
    border-left: 1px solid #eeeeee;
}

.push {
    margin-bottom: 15px !important;
}

.push-top {
    margin-top: 15px !important;
}

.push-top-bottom {
    margin-top: 40px;
    margin-bottom: 40px;
}

.push-bit {
    margin-bottom: 10px !important;
}

.push-bit-top {
    margin-top: 10px !important;
}

.push-bit-top-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
}

.push-right {
    margin-right: 15px;
}

.push-left {
    margin-left: 15px;
}

.push-right-left {
    margin-left: 10px;
    margin-right: 10px;
}

.push-inner-top-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
}

.overflow-hidden {
    overflow: hidden;
}

.display-none {
    display: none;
}

.remove-margin {
    margin: 0 !important;
}

.remove-margin-bottom {
    margin-bottom: 0 !important;
}

.remove-padding {
    padding: 0 !important;
}

.remove-radius {
    border-radius: 0 !important;
}

.remove-radius-top {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.remove-radius-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.lt-ie10 .hidden-lt-ie10 {
    display: none !important;
}

.visible-lt-ie10 {
    display: none;
}

.lt-ie10 .visible-lt-ie10 {
    display: block;
}

.placeholder {
    color: #999;
}

:focus {
    outline: 0 !important;
}

/*
=================================================================
(#m08ths) THEMES
=================================================================
*/

/* Default Color Theme specific colors */
.themed-color {
    color: #5ccdde !important;
}

.themed-background {
    background-color: #5ccdde !important;
}

.themed-color-dark {
    color: #454e59 !important;
}

.themed-background-dark {
    background-color: #454e59 !important;
}

/* Contextual Background Colors */
.themed-background-danger {
    background-color: #de815c !important;
}

.themed-background-warning {
    background-color: #deb25c !important;
}

.themed-background-info {
    background-color: #5cafde !important;
}

.themed-background-success {
    background-color: #afde5c !important;
}

.themed-background-muted {
    background-color: #f9f9f9 !important;
}

/*
=================================================================
(#m09res) RESPONSIVE
=================================================================
*/

/* Small devices, Tablets (>767px) */
@media screen and (min-width: 768px) {

    /* General */
    #login-container {
        width: 380px;
        top: 120px;
        margin-left: -190px;
    }

    #page-content {
        padding: 20px 20px 1px;
    }

    #page-container.header-fixed-top #page-content {
        padding-top: 70px;
    }

    #page-container.header-fixed-bottom #page-content {
        padding-bottom: 70px;
    }

    #page-content.inner-sidebar-left,
    #page-content.inner-sidebar-right {
        position: relative;
    }

    #page-content.inner-sidebar-left {
        padding-left: 240px;
    }

    #page-content.inner-sidebar-right {
        padding-right: 240px;
    }

    #page-content-sidebar {
        margin: 0;
        width: 220px;
        position: absolute;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        border-bottom: none;
    }

    #page-content.inner-sidebar-left #page-content-sidebar {
        left: 0;
        border-right: 1px solid #dae0e8;
    }

    #page-container.sidebar-light #page-content.inner-sidebar-left #page-content-sidebar {
        border-left: 1px solid #dae0e8;
    }

    #page-content.inner-sidebar-right #page-content-sidebar {
        right: 0;
        border-left: 1px solid #dae0e8;
    }

    #page-container.header-fixed-top #page-content-sidebar {
        top: 50px;
    }

    #page-container.header-fixed-bottom #page-content-sidebar {
        bottom: 50px;
    }

    .content-header,
    .content-header-media .header-section,
    .block,
    .block.full,
    .modal-body {
        padding-left: 20px;
        padding-right: 20px;
    }

    .block .block-content-full {
        margin: -20px -20px -1px;
    }

    .block.full .block-content-full {
        margin: -20px;
    }

    .content-header {
        margin: -20px -20px 20px;
    }

    .block,
    .widget {
        margin-bottom: 20px;
    }

    .block-title {
        margin-left: -20px;
        margin-right: -20px;
    }

    /* Forms */
    .form-bordered {
        margin-left: -20px;
        margin-right: -20px;
    }

    .form-bordered .form-group {
        padding-left: 20px;
        padding-right: 20px;
    }

    .form-horizontal.form-bordered .form-group {
        padding-left: 5px;
        padding-right: 5px;
    }

    /* Tabs */
    .nav-tabs > li > a {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 3px;
        margin-right: 3px;
    }

    /* Modal */
    .modal-dialog {
        margin: 60px auto;
    }

    .modal-header,
    .modal-footer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-header .nav-tabs {
        margin-left: -20px;
        margin-right: -20px;
    }

    /* Error Page */
    #error-container h1 {
        font-size: 96px;
        margin: 50px 0 70px;
    }

    /* Timeline */
    .timeline-list li {
        padding: 20px 0;
    }

    .timeline-list:after {
        position: absolute;
        display: block;
        width: 4px;
        top: 0;
        left: 134px;
        bottom: 0;
        content: "";
        background-color: #f9f9f9;
        z-index: 1;
    }

    .timeline-icon {
        left: 115px;
        right: auto;
    }

    .timeline-time {
        float: left;
        padding: 0;
        text-align: right;
        width: 100px;
        height: 40px;
        line-height: 40px;
        border-bottom: none;
        margin-bottom: 0;
        background-color: transparent;
    }

    .timeline-content {
        padding-left: 166px;
    }
}

/* Medium devices, Desktops (>991px) */
@media screen and (min-width: 992px) {

    /* Alternative Sidebar */
    #page-container.sidebar-alt-visible-xs #sidebar-alt {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        right: -220px;
    }

    #page-container.sidebar-alt-visible-lg #sidebar-alt {
        -webkit-transform: translateX(-220px);
        transform: translateX(-220px);
    }

    #page-container.sidebar-alt-visible-xs #sidebar-alt-close {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        right: -30px;
        opacity: 0;
    }

    #page-container.sidebar-alt-visible-lg #sidebar-alt-close {
        -webkit-transform: translateX(-250px);
        transform: translateX(-250px);
        opacity: 1;
    }

    /* Sidebar Reset Small Screens Classes */
    #page-container.sidebar-visible-xs #sidebar {
        width: 0;
    }

    #page-container.sidebar-visible-xs #sidebar,
    #page-container.sidebar-visible-xs #main-container {
        -webkit-transform: none;
        transform: none;
    }

    /* Layout Transitions */
    #sidebar {
        -webkit-transition: background-color .2s ease-out;
        transition: background-color .2s ease-out;
    }

    #main-container {
        -webkit-transition: none;
        transition: none;
    }

    header.navbar.navbar-fixed-top,
    header.navbar.navbar-fixed-bottom {
        -webkit-transition: background .2s ease-out;
        transition: background .2s ease-out;
    }

    #page-container.sidebar-visible-lg-mini #sidebar-toggle-full,
    #page-container.sidebar-visible-lg-full #sidebar-toggle-mini {
        display: inline-block;
    }

    #page-container.sidebar-visible-lg-mini #sidebar-toggle-mini,
    #page-container.sidebar-visible-lg-full #sidebar-toggle-full {
        display: none;
    }

    /* Sidebar + Static Layout */
    #sidebar {
        width: 0;
        left: 0;
    }

    #main-container {
        margin-left: 0;
        margin-right: 0;
    }

    #page-container.sidebar-visible-lg-mini #sidebar {
        width: 40px;
    }

    #page-container.sidebar-visible-lg-mini #main-container {
        margin-left: 40px;
    }

    #page-container.sidebar-visible-lg-full #sidebar {
        width: 220px;
    }

    #page-container.sidebar-visible-lg-full #main-container {
        margin-left: 220px;
    }

    /* Sidebar + Fixed Layout */
    #page-container.header-fixed-top #sidebar,
    #page-container.header-fixed-bottom #sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
    }

    #page-container.header-fixed-top #sidebar-brand,
    #page-container.header-fixed-bottom #sidebar-brand {
        display: block;
    }

    #page-container.sidebar-visible-lg-mini header.navbar-fixed-top,
    #page-container.sidebar-visible-lg-mini header.navbar-fixed-bottom {
        left: 40px;
    }

    #page-container.sidebar-visible-lg-full header.navbar-fixed-top,
    #page-container.sidebar-visible-lg-full header.navbar-fixed-bottom {
        left: 220px;
    }

    /* Sidebar Condensed Menu */
    #page-container.sidebar-visible-lg-mini #sidebar {
        overflow: visible;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-content {
        width: 40px;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav-mini-hide {
        display: none;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav li {
        position: relative;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav .sidebar-nav-menu + ul {
        position: absolute;
        width: 220px;
        top: 0;
        right: -220px;
        margin: 0;
        padding: 6px 5px;
        background-color: #363e46;
    }

    #page-container.sidebar-visible-lg-mini.sidebar-light #sidebar .sidebar-nav .sidebar-nav-menu + ul {
        background-color: #e6e6e6;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav ul a {
        margin: 0;
        padding-left: 10px;
        border-left: none;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav ul ul {
        margin-left: 0;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav > li.active > ul {
        display: none;
    }

    #page-container.sidebar-visible-lg-mini #sidebar .sidebar-nav .sidebar-nav-menu.open + ul {
        display: block !important;
    }

    /* Navbar Nav Search */
    .navbar-form-custom {
        width: 300px;
    }

    /* Chat */
    .chatui.chatui-window.widget {
        right: 70px;
        margin-right: 0;
    }
}

/*
=================================================================
(#m10prs) PRINT
=================================================================
*/

@media print {
    #sidebar, #sidebar-alt, .content-header, .breadcrumb-top, .block-title .block-options {display: none !important;}
    #page-content {min-height: 0 !important; height: auto !important; padding: 0 !important;}
    #main-container {margin: 0 !important;}
    .block, .block.full {border: none !important; padding: 0 !important;}
    .block-title {margin: 0 0 20px !important;}
}